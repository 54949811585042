import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

export const PRIME_VUE_CONFIG = {
  theme: {
    preset: definePreset(Aura, {
      semantic: {
        primary: {
          50: '{zinc.50}',
          100: '{zinc.100}',
          200: '{zinc.200}',
          300: '{zinc.300}',
          400: '{zinc.400}',
          500: '{zinc.500}',
          600: '{zinc.600}',
          700: '{zinc.700}',
          800: '{zinc.800}',
          900: '{zinc.900}',
          950: '{zinc.950}',
        },
      },
    }),
    options: {
      darkModeSelector: '.fake-dark-selector',
    },
  },
};

export const ICON_SPRITE_URL = window.bst.iconSpriteUrl || '/icons/icons.svg';
export const LANG = document.documentElement.lang || 'de';
export const BREAKPOINTS = {
  s: '0px',
  m: '375px',
  l: '768px',
  xl: '1200px',
  xxl: '1400px',
};
